import React from 'react'
import '../CSS/DarkMode/Footer/Footer.css'
import BlogData from "../../data.json"
import { HashLink } from 'react-router-hash-link'

const Footer = (prop) => {
    const date = new Date()
    return (
        <div className='footerCont'>
            <div className="footerRapper" style={{ "--footer-width": prop.width }}>
                <div className="infoCont">
                    <div className="discoverCont info">
                        <p>Discover</p>
                        <HashLink to='/#services'>Services</HashLink>
                        <HashLink to='/#products'>Product</HashLink>
                        <HashLink to='/#sum'>SUM</HashLink>
                        <HashLink to="/blogs#BackTop">Martian Blogs</HashLink>
                    </div>
                    <div className="learnAboutCont info">
                        <p>Learn about</p>
                        <a href={BlogData[7].linkto} target='blank'>What is IaaS ,PaaS & SaaS?</a>
                        <a href={BlogData[6].linkto} target='blank'>What is Cloud Computing?</a>
                        <a href={BlogData[5].linkto} target='blank'>What is Cyber Security?</a>
                        <a href={BlogData[0].linkto} target='blank'>What is AI?</a>
                    </div>
                    <div className="connectWithUsCont info">
                        <p>Connect with us</p>
                        <a href='mailto:support@martiancorporation.com' to="/contact#BackTop">Connect Support Team</a>
                        <a href="mailto:sales@martiancorporation.com ">Connect Sales Team</a>
                        <a href="mailto:cto@martiancorporation.com ">Connect Tech Team</a>
                    </div>
                    <div className="anoutMartianCont info">
                        <p>About Martian</p>
                        <HashLink to="/about#BackTop">About us</HashLink>
                        <HashLink to="/career#BackTop">Career</HashLink>
                        <p>Notice</p>
                        <p>Investor Relationship</p>
                        <p>Latest News</p>
                    </div>
                </div>
                <div className="regionCont">
                    <div className="regionWrapper">
                        <p>Select a country/region</p>
                        <div className='choseRegion'>
                            <p>India - English</p>
                        </div>
                    </div>
                </div>
                <div className='hrCont'>
                    <hr />
                </div>
                <div className="footerSection">
                    <div className="footerBottomCont">

                        <HashLink to="/contact#Contact" className='hyperLink'>Contact</HashLink>
                        <HashLink to='/privacy#BackTop' className='hyperLink'> Terms and Conditions</HashLink>
                        <HashLink to='/refund#BackTop' className='hyperLink'>Refund Policy</HashLink>
                        <HashLink to='/pricing#BackTop' className='hyperLink'>Pricing</HashLink>
                        {/* <p>Accessibility</p> */}
                    </div>
                    <p>&copy;  2023 - {date.getFullYear()} Martian Corporation &nbsp;&nbsp;|&nbsp;&nbsp; All rights reserved</p>
                </div>
            </div>
        </div>
    )
}

export default Footer
